// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../svg";
import { currencyChange } from "../../store/currency";
import { localeChange } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";

// data stubs
import currencies from "../../data/shopCurrencies";

function MobileMenu(props) {
	const {
		mobileMenuState,
		closeMobileMenu,
		changeLocale,
		changeCurrency,
		fetchedData,
	} = props;
	const morePgae = fetchedData?.pages?.filter((page) =>
		page?.pageCategory?.some((category) => category?.name === "المزيد")
	);

	const links = [
		{
			type: "main-link",
			label: "الرئيسية",
			url: `/`,
		},

		{
			type: fetchedData?.categories?.length !== 0 && "menu",
			label: fetchedData?.categories?.length !== 0 && "التصنيفات",
			url: "",
			children: fetchedData?.categories?.map((item) => ({
				type: "main-link",
				label: item?.name,
				classBg: true,
				url: `/shop/products-by-category/${item?.id}`,
				children: item?.subcategory?.map((sub) => ({
					type: "main-link",
					label: sub?.name,
					classBg: true,
					url: `/shop/products-by-category/${sub?.id}`,
				})),
			})),
		},

		{
			type: "main-link",
			label: "المنتجات",
			url: `/shop/products`,
		},

		{
			type: "main-link",
			label: "المقالات",
			url: `/blog/posts`,
		},
		{
			type: "main-link",
			label: "تواصل معنا",
			url: `/site/contact-us`,
		},

		{
			type: morePgae?.length !== 0 && "menu",
			label: morePgae?.length !== 0 && "المزيد",
			url: "",
			children: morePgae?.map((item) => ({
				type: "main-link",
				label: item?.title,
				url: `/site/SitePages/${item?.id}`,
			})),
		},
		{
			type: "menu",
			label: "التسوق",
			url: "",
			children: [
				{
					type: "main-link",
					label: "المقارنات",
					url: `/shop/compare`,
				},
				{
					type: "main-link",
					label: "المفضله",
					url: `/shop/wishlist`,
				},
			],
		},

		{
			type: "menu",
			label: "حسابي",
			url: "",
			children: [
				{
					type: "main-link",
					label: "لوحة التحكم",
					url: `/account/dashboard`,
				},
			],
		},
	];

	const classes = classNames("mobilemenu", {
		"mobilemenu--open": mobileMenuState.open,
	});

	const handleItemClick = (item) => {
		if (item.data) {
			if (item.data.type === "language") {
				changeLocale(item.data.locale);
				closeMobileMenu();
			}
			if (item.data.type === "currency") {
				const currency = currencies.find(
					(x) => x.currency.code === item.data.code
				);

				if (currency) {
					changeCurrency(currency.currency);
					closeMobileMenu();
				}
			}
		}
		if (item.type === "main-link") {
			closeMobileMenu();
		}
	};

	return (
		<div className={classes}>
			{/* eslint-disable-next-line max-len */}
			{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
			<div className='mobilemenu__backdrop' onClick={closeMobileMenu} />
			<div className='mobilemenu__body'>
				<div className='mobilemenu__header'>
					<div className='mobilemenu__title'>القائمة</div>
					<button
						type='button'
						className='mobilemenu__close'
						onClick={closeMobileMenu}>
						<Cross20Svg />
					</button>
				</div>
				<div className='mobilemenu__content'>
					<MobileLinks
						links={links}
						onItemClick={handleItemClick}
						closeMobileMenu={closeMobileMenu}
					/>
				</div>
			</div>
		</div>
	);
}

const mapStateToProps = (state) => ({
	mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
	closeMobileMenu: mobileMenuClose,
	changeLocale: localeChange,
	changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
